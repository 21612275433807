<template>
    <div>
        <label class="flex">
            {{label}}
            <span
                v-if="isRequired"
                class="text-red-500"
            >*</span>
        </label>
        <textarea
            @input='$emit("input", $event.target.value)'
            class="textarea"
            :class="[error ? 'border-red-500' : 'border-gray-200']"
            :name="name"
            id=""
            cols="5"
            rows="5"
            :placeholder="placeholder"
            :value="value"
            :maxlength="maxlength"
        ></textarea>
        <p
            v-if="error"
            class="text-red-500 small mb-15"
        >
            {{error[0]}}
        </p>
    </div>
</template>
<script>
export default {
    name: 'Textare',
    props: ['label', 'name', 'placeholder', 'isRequired', 'value', 'maxlength', 'error']
}
</script>
