<template>
    <div class="max-w-lg mx-auto mt-257 px-12 md:px-0">
        <!-- Form title -->
        <Title
            :title="$t('signUpFormTitle')"
            :subtitle="$t('signUpFormSubtitle')"
        />

        <!-- General error message -->
        <p
            v-if="error"
            class="text-red-500 small my-10"
        >
            {{error}}
        </p>

        <!-- Signup form -->
        <form
            ref="signUpForm"
            @submit.prevent="onSubmit"
            @input="onEmptyFieldsDisableBtn"
            @change="onEmptyFieldsDisableBtn"
        >
            <!-- ONG Name field -->
            <Input
                v-model="signupData.ngo_name"
                :error="errors.ngo_name"
                :class="[errors.ngo_name ? 'mb-4' : 'mb-22']"
                :label="$t('ongNameFieldLabel')"
                :placeholder="$t('ongNameFieldPlaceholder')"
                type="text"
                name="ongName"
                :isRequired="true"
            />

            <!-- ONG CIF/CUI -->
            <Input
                v-model="signupData.cif"
                :error="errors.cif"
                :class="[errors.cif ? 'mb-4' : 'mb-22']"
                :label="$t('ongCUIFieldLabel')"
                :placeholder="$t('ongCUIFieldPlaceholder')"
                type="text"
                name="ongCUI"
                :isRequired="true"
            />

            <!-- ONG Registration Number -->
            <Input
                v-model="signupData.registration_number"
                :error="errors.registration_number"
                :class="[errors.registration_number ? 'mb-4' : 'mb-22']"
                :label="$t('ongRegistrationFieldLabel')"
                :placeholder="$t('ongRegistrationPlaceholder')"
                type="text"
                name="ongNumber"
                :isRequired="true"
            />

            <!-- Services type -->
            <div class="mb-22">
                <label class="flex">{{$t('servicesFieldLabel')}}<span class="text-red-500">*</span></label>

                <Multiselect
                    @input="onEmptyFieldsDisableBtn"
                    class="rounded border"
                    :class="[errors.services ? 'border-red-500' : 'border-gray-200']"
                    v-model="service"
                    :options="services"
                    :multiple="true"
                    label="name"
                    track-by="id"
                    :placeholder="$t('servicesFieldPlaceholder')"
                    :deselectLabel="$t('selectRemoveOptionText')"
                    :selectLabel="$t('selectOptionText')"
                    :selectedLabel="$t('selectOptionSelectedText')"
                    :selectGroupLabel="$t('selectGroupOptionsText')"
                    :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                >
                    <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                    <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                    <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                </Multiselect>
                <p
                    v-if="errors.services"
                    class="text-red-500 small mt-4"
                >
                    {{errors.services[0]}}
                </p>
            </div>

            <!-- Address -->
            <label class="flex">{{ $t('addressCountyFieldLabel') }}<span class="text-red-500">*</span> </label>
            <div class="grid md:grid-cols-2 gap-30 mb-15">
                <!-- County -->
                <div>
                    <Multiselect
                        @input="onEmptyFieldsDisableBtn"
                        class="rounded border"
                        :class="[errors.county_id ? 'border-red-500' : 'border-gray-200']"
                        @select="countySelected"
                        v-model="county"
                        :custom-label="getMultiselectNameKey"
                        :options="counties"
                        :placeholder="$t('addresCountyPlaceholder')"
                        :deselectLabel="$t('selectRemoveOptionText')"
                        :selectLabel="$t('selectOptionText')"
                        :selectedLabel="$t('selectOptionSelectedText')"
                    >
                        {{county}}
                        <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                        <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                    </Multiselect>
                    <p
                        v-if="errors.county_id"
                        class="text-red-500 small mt-4"
                    >
                        {{errors.county_id[0]}}
                    </p>
                </div>

                <!-- City -->
                <div>
                    <Multiselect
                        @input="onEmptyFieldsDisableBtn"
                        class="rounded border"
                        :class="[errors.county_id ? 'border-red-500' : 'border-gray-200']"
                        v-model="city"
                        :custom-label="getMultiselectNameKey"
                        :options="cities"
                        :placeholder="$t('addressCityPlaceholder')"
                        :deselectLabel="$t('selectRemoveOptionText')"
                        :selectLabel="$t('selectOptionText')"
                        :selectedLabel="$t('selectOptionSelectedText')"
                    >
                        {{city}}
                        <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                        <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                    </Multiselect>
                    <p
                        v-if="errors.city_id"
                        class="text-red-500 small mt-4"
                    >
                        {{errors.city_id[0]}}
                    </p>
                </div>
            </div>

            <!-- Street -->
            <Input
                v-model="signupData.address"
                :error="errors.address"
                :class="[errors.address ? 'mb-4' : 'mb-22']"
                :placeholder="$t('addressStreetPlaceholder')"
                type="text"
                name="street"
            />

            <!-- Website -->
            <Input
                v-model="signupData.website"
                :error="errors.website"
                :class="[errors.website ? 'mb-4' : 'mb-22']"
                :label="$t('websiteFieldLabel')"
                :placeholder="$t('websiteFieldPlaceholder')"
                type="text"
                name="website"
                :isRequired="true"
            />

            <!-- Description -->
            <Textarea
                v-model="signupData.description"
                :error="errors.description"
                :class="[errors.description ? 'mb-4' : 'mb-22']"
                :label="$t('descriptionFieldLabel')"
                name="description"
                :placeholder="$t('descriptionFieldPlaceholder')"
                :isRequired="true"
            />

            <!-- Observations -->
            <Textarea
                v-model="signupData.observations"
                class="mb-22"
                :label="$t('observationsFieldLabel')"
                name="observations"
                :placeholder="$t('descriptionFieldPlaceholder')"
            />

            <!-- Name -->
            <Input
                v-model="signupData.user_name"
                :error="errors.user_name"
                :class="[errors.user_name ? 'mb-4' : 'mb-22']"
                :label="$t('nameFieldLabel')"
                :placeholder="$t('nameFieldPlaceholder')"
                type="text"
                name="name"
                :isRequired="true"
            />

            <!-- Email -->
            <Input
                v-model="signupData.user_email"
                :error="errors.user_email"
                :class="[errors.user_email ? 'mb-4' : 'mb-22']"
                :label="$t('emailFieldLabel')"
                :placeholder="$t('emailFieldPlaceholder')"
                type="email"
                name="email"
                :isRequired="true"
            />

            <!-- Phone -->
            <Input
                v-model="signupData.user_phone"
                :error="errors.user_phone"
                :class="[errors.user_phone ? 'mb-4' : 'mb-22']"
                :label="$t('phoneFieldLabel')"
                :placeholder="$t('phoneFieldPlaceholder')"
                type="phone"
                name="phone"
                :isRequired="true"
            />

            <!-- Checkbox -->
            <Checkbox
                :class="[errors.consent ? 'mb-4' : 'mb-37']"
                :error="errors.consent"
                :labelTextStart="$t('checkboxFieldLabelStart')"
                :linkText="$t('checkboxFieldLabelLinkText')"
                :labelTextEnd="$t('checkboxFieldLabelEnd')"
                :link="termsAndConditionsLink"
                name='termsAndConditions'
                v-model="signupData.consent"
            />

            <!-- Submit -->
            <div class="w-full flex justify-center mb-36">
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    role="submit"
                    :disabled="submitBtnDisabled || registerLoading"
                    :text="$t('signUpButtonText')"
                    :spinning="registerLoading"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Textarea from '@/components/form/Textarea'
import Checkbox from '@/components/form/Checkbox'
import Button from '@/components/buttons/Button'
import Multiselect from 'vue-multiselect'
import Title from '@/components/texts/FormTitle'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'SignupInit',

    components: {
        Input,
        Multiselect,
        Textarea,
        Checkbox,
        Button,
        Title
    },

    data () {
        return {
            service: [],
            county: '',
            city: '',
            submitBtnDisabled: true,
            registerLoading: false,
            termsAndConditionsLink: process.env.VUE_APP_TERMS_AND_CONDITIONS
        }
    },

    computed: {
        /** Get vuex state */
        ...mapGetters(['services', 'counties', 'cities', 'signupData', 'errors', 'error', 'token', 'userType', 'prefix'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['fetchCounties', 'fetchCities', 'signupInit', 'fetchServices']),

        /** Multiselect return only name from object value. */
        getMultiselectNameKey ({ name }) {
            return `${name}`
        },

        countySelected (event) {
            /** Extract the cities of the selected county. */
            this.fetchCities(event.id)
        },

        /** Activate submit button only when fields contain data */
        onEmptyFieldsDisableBtn () {
            this.signupData.county_id = this.county.id
            this.signupData.city_id = this.city.id

            if (
                this.signupData.ngo_name &&
                this.signupData.cif &&
                this.signupData.registration_number &&
                this.service.length &&
                this.signupData.county_id &&
                this.signupData.city_id &&
                this.signupData.address &&
                this.signupData.website &&
                this.signupData.description &&
                this.signupData.user_name &&
                this.signupData.user_email &&
                this.signupData.user_phone &&
                this.signupData.consent
            ) {
                this.submitBtnDisabled = false
            } else {
                this.submitBtnDisabled = true
            }
        },

        /** Register user. */
        onSubmit () {
            this.registerLoading = true
            this.signupData.county_id = this.county.id
            this.signupData.city_id = this.city.id

            this.service.map((service) => {
                this.signupData.services.push(service.id)
            })

            this.signupData.user_phone = this.prefix + this.signupData.user_phone

            this.signupInit(this.signupData)
                .then(() => {
                    this.registerLoading = false
                })
        }
    },

    created () {
        /** Extract all the counties. */
        this.fetchCounties()

        /** Extract all the services. */
        this.fetchServices()
    }
}
</script>
