<template>
    <div>
        <label class="checkbox-container">
            <span class="text-purple-900 small-bold">
                {{labelTextStart}}
                <a
                    class="text-indigo-300 underline"
                    :href="link"
                >{{linkText}}</a>
                {{labelTextEnd}}
            </span>
            <input
                :id="name"
                type="checkbox"
                @change='$emit("input", $event.target.checked)'
                class="checkbox"
                ref="input"
                :checked="checked"
                :default-checked.prop="checked"
                :disabled="disabled ? 'disabled' : false"
            >
            <span class="checkmark" :style="spanStyle ? spanStyle : ''"></span>
        </label>
        <p
            v-if="error"
            class="text-red-500 small mb-22 inputErrors"
        >
            {{error[0]}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: ['labelTextStart', 'labelTextEnd', 'link', 'linkText', 'name', 'error', 'checked', 'disabled', 'spanStyle'],
    methods: {
        getValue () {
            return this.$refs.input.value
        }
    }
}
</script>
